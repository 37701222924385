<template>
  <section class="relative" data-aller-id="newsletterslim">
    <AllerImgixImg width="394" height="285" class="aspect-[394/285] lg:aspect-[16/5] rounded-10 object-cover object-center" :render-widths="[394, 788, 920, 1840]" loading="lazy" sizes="(max-width: 768px) calc(100vw - 20px), 920px" :media="{ imageUrl: '/frontend/nyhedsbrev.jpg' }" alt="Nyhedsbrev" />
    <div class="bg-pink-400 w-[213px] h-[213px] rounded-[207px] rounded-bl-none pt-30 pl-10 pr-25 pb-15 flex flex-col items-center justify-center absolute left-0 bottom-0">
      <h2 class="text-white font-heebo font-bold text-21 leading-24 text-center mb-13 pl-13">Vi holder dig<br />opdateret i vores<br />nyhedsbrev</h2>
      <form @submit.prevent="navToNewsletter">
        <div class="input mb-13">
          <input type="email" id="email" v-model="email" placeholder=" " aria-label="E-mail" required />
          <label for="email">Skriv din mailadresse</label>
        </div>
        <button aria-label="Tilmeld" type="submit" class="px-20 py-5 bg-black text-white rounded-20 uppercase font-heebo font-bold text-14 leading-20 tracking-[0.56px] hover:bg-red-400">Tilmeld</button>
      </form>
    </div>
  </section>
</template>
<script setup lang="ts">
const email = ref('');
const navToNewsletter = () => {
  navigateTo({ name: 'nyhedsbrev', query: { email: email.value } })
}
</script>

<style lang="css" scoped>
.input {
  @apply relative flex-grow;
}

.input input {
  @apply w-full h-full pr-10 font-semibold rounded-20 border border-opacity-25 p-5 focus:border-blue-400 px-20 bg-white;
}

.input input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.input input:focus {
  @apply outline-none;
}

.input input + label {
  @apply !font-sans leading-20 absolute top-0 text-14 left-0 italic font-light mt-5 transition-all duration-200 ease-linear px-10;
}

.input input:not(:placeholder-shown) + label,
.input input:focus + label {
  @apply ml-13 -mt-20 bg-white not-italic rounded-t-md text-12 px-5 border border-b-0 border-blue-400;
}
</style>
