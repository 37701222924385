<template>
  <AllerAptoma domain="https://www.billedbladet.dk">
    <template #placeholders="slotProps">
      <SectionHoroscopeSlider v-if="slotProps.placeholderMapping === 'horoscopes_fullwidth'" lazy-img="lazy" />
      <SectionNewsletter v-if="slotProps.placeholderMapping === 'newsletter_fullwidth'" />
      <AllerReadPeak  v-if="slotProps.placeholderMapping === 'readpeak_fullwidth' && route.fullPath !== '/'" />
      <div v-else />
    </template>
  </AllerAptoma>
</template>

<script setup lang="ts">
const route = useRoute();
</script>

<style scoped>
.aptoma-outer {
  @apply overflow-hidden;
}
:deep(.dre-group:first-child:has(.dre-item--feature-headerType-anim)) {
  @apply pt-[27px];
}
</style>
